<template>
  <section class="section">
    <div v-if="!newsletterConfirmed.success" class="container">
      <div class="columns head-column">
        <div class="column is-half is-offset-one-quarter">
          <h1>Bestätigung der Newsletter-Anmeldung wird geprüft</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <progress class="progress is-large is-info" max="100"></progress>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <p>einen Moment bitte...</p>
        </div>
      </div>
    </div>
    <div v-if="newsletterConfirmed.success" class="container">
      <div class="columns head-column">
        <div class="column is-half is-offset-one-quarter">
          <h1>Vielen Dank für die Bestätigung Deiner Newsletter-Anmeldung.</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "confirmNewsletter",
  data() {
    return {
      hash: this.$route.params.hash
    };
  },
  created: function() {
    // this.$store.dispatch("emailValidation", this.$route.params.hash);
    this.confirmEmail();
  },
  methods: {
    confirmEmail() {
      if (this.$route.params.hash) {
        this.$store.dispatch("confirmNewsletter", this.$route.params.hash);
      }
    }
  },
  computed: {
    ...mapGetters(["newsletterConfirmed"])
  }
};
</script>